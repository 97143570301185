/* Provide sufficient contrast against white background */
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans');*/

a {
    color: #3C289B;
}

code {
  color: #E01A76;
}

.btn {
    font-family: "AB Gilroy";
}

.btn-primary {
    color: #fff;
    background-color: #3C289B;
    border-color: #24185D;
}

.btn-primary:hover {
    color: #fff;
    border-color: #3C289B;
    background-color: #24185D;
}
.user-select-none {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

div.embed-container {
    position: absolute;
    top: 148px;
    bottom: 6px; /* Prevent scrollbar from showing up in non-Chrome browsers */
    width: 100%;
}

div.embed-container > div {
  height: 100%;
}

div.report-loading {
    position: absolute;
    top: 45%;
    width: 100%;
    font-family: "AB Gilroy";
    font-size: 24px;
    font-weight: 100;
    text-align: center;
}

div#top-ribbon {
    width: 100%;
    height: 28px;
    margin: 0;
    padding: 0;
    background-color: #24185D;
    top: 0;
    left: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-family: "AB Gilroy";
    color: white;
}

div#top-ribbon div.rco-banner div.pull-left {
    display: inline;
}
  div#top-ribbon div.rco-banner div.pull-left a {
    color: #DAF1FC;
    display: inline;
    margin-left: 10px;
    line-height: 24px;
    font-size: 13px;
    font-family: 'AB Gilroy';
    text-transform: uppercase;
    font-weight: 400;
  }

div#top-ribbon div.rco-banner ::before {
    content: '|';
    padding-left: 10px;
    position: relative;
    display: inline-block;
    color: #DAF1FC;

    font-size: 14px;
}

div#top-ribbon div.rco-banner :first-child::before {
    content: '';
    padding-left: 0px;
}

div#top-ribbon div.abc-user {
    font-weight: 600;
    font-size: 12px;
    width: 15%;
}

div#top-ribbon div.rco-banner {
    line-height: 28px;
    margin-left: 20px;
}

div#top-ribbon li.nav-item {
    
    margin-top: 15px;
}


div#middle-ribbon {
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 0;
    background-color: #3C289B;
    top: 28px;
    left: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

      div#middle-ribbon div.rco-banner {
        font-weight: 100;
        font-family: "AB Gilroy";
        font-size: 24px;
        line-height: 28px;
        margin-left: 30px;
        color: white;
        width: 90%;
      }

div#middle-ribbon div.rco-banner a {
    color: white;
    text-decoration: none;
    font-weight: 600;
}

div#bottom-ribbon {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: rgb(239, 239, 239);
  top: 78px;
  left: 0;
  font-weight: 600;
  font-family: "AB Gilroy";
  font-size: 24px;
  /* line-height: 28px; */
}

div#bottom-ribbon nav.rco-navbar {
    display: flex !important;
    flex-basis: auto;
}

div#bottom-ribbon nav {
    margin-left: 15px;
}

a.rco-navlink {
    font-size: 16px;
    font-weight: 400;
    display: block;
    padding: 0.5rem 1rem;
    font-family: "AB Gilroy";
}

a.rco-navlink-selected {
    border-bottom: 3px solid rgb(0, 0, 0);
    color: black;
    height: 40px;
    font-weight: 600;
    font-weight: 600;
    font-family: "AB Gilroy";
}

a.rco-navlink-dropdown {
    color: black;
    height: 40px;
    font-weight: 600;
    font-family: "AB Gilroy";
}

div.page-not-found {
    text-align: center;
}

a.signout-nav,
button.signout-nav {
    font-weight: 400;
    font-size: 14px;
    color: #DAF1FC;
}

button.signout-nav {
  padding: 0;
  margin-top: -3px;
}

button.signout-nav:hover {
  color: #DAF1FC;
}

nav.signout-navbar {
    padding: 0;
}

div.top-right {
    margin-left: auto;
    margin-right: 20px;
    font-size: 14px;
}

button.rco-err-msg {
  text-align: left;
}

div.err-msg-tbl {
  display:block;
  width:100%;
}

div.tbl-no-results {
  font-size: 1.5rem;
}

span.load-font {
  font-weight: 600;
}

td.center-icon {
    text-align: center;
}

svg.button-icon {
  margin-left: 0.3em;
  margin-top: -0.2em;
}

table.rco-table {
    text-align: center;
}