.dropdown {
	max-width: 300px;
	min-width: 150px;
	height: 100%;
	position: relative;
	box-sizing: border-box;
	min-height: 33px;
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	cursor: pointer !important;
}

.dropdown-header-wrapper {
	min-height: inherit;
	width: 100%;
	margin: 0;
}

.dropdown-header {
	height: 40px;
	color: #FFF;
	background-color: #24185D;
	padding-left: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.dropdown-header-text {
	color: #FFF;
	width: 260px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.dropdown-header-caret {
	width: 17px;
	height: 17px;
	position: absolute;
	right: 7px;
	fill: #FFF;
}

.dropdown-menu-wrapper {
	margin-top: -2px !important;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	top: 100%;
	right: 0px;
	background-color: #FFF;
	z-index: 14;
}

.dropdown-menu-window {
	max-height: 500px;
	min-height: auto;
	overflow-y: hidden;
	overflow-x: hidden;
	width: 100%;
	background-color: inherit;
	text-overflow: ellipsis;
	padding: 10px 0px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 7px 0px;
	margin: 0px;
	font-size: 12px;
	line-height: 14px;
	font-weight: 400;
}

.dropdown-facility-selection {
	min-height: 30px;
	max-height: 460px;
	overflow: hidden scroll;
	font-size: 12px;
	line-height: 14px; 
	font-weight: 400;
}

.facility-list {
	display: flex;
	flex-direction: column;
}

.facility-list :hover {
	background-color: rgb(250, 250, 250);
}

.facility-header {
	color: rgb(102, 102, 102);
	padding-left: 16px;
	margin-bottom: 5px;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
}

.facility-row-wrapper {
	color: rgb(51, 51, 51) !important;
	background-color: #FFF;
	padding-left: 20px;
	margin-left: 0px;
	display: flex;
	flex-direction: row;
	padding: 12px 24px 12px 12px;
}

.facility-row {
	width: 245px;
}

.facility-row-customer-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 16px;
	line-height: 19px;
	font-weight: 400;
}

.facility-row-customer-corp-key {
	color: rgb(0, 83, 155)
}

.facility-row-wrapper-active {
	box-shadow: rgb(30, 146, 204) -8px 0px 0px;
	background-color: rgb(239, 239, 239);
	padding-left: 12px;
	margin-left: 8px;
}

.facility-row-wrapper-active :hover {
	background-color: rbg(239, 239, 239);
}

.facility-row-selected-badge {
	padding: 0px 8px;
	display: inline-table;
	margin: auto 0px 4px 4px;
	text-align: center;
	border-radius: 100px;
	border: medium none;
	background-color: rgb(30, 146, 204);
}

.facility-row-selected-badge-font {
	color: #FFF;
	font-size: 11px;
	font-weight: 600;
	line-height: 1em;
}

.facility-row-selected-badge-text {
	color: #FFF;
	font-size: 11px;
	line-height: 13px;
	font-weight: 400;
	text-transform: uppercase;
}

