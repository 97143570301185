.truncate-text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-align: left;
}

.table {
	table-layout: fixed;
	width: 100%;
}

.column-btn {
	width: 100%;
	padding: 0.375rem 0rem;
	text-overflow:ellipsis;
	overflow:hidden;
}
