a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.dd-wrapper {
  position: relative;
  width: auto;
  user-select: none;
}

.dd-wrapper button {
  overflow: visible;
  width: inherit;
  margin: inherit;
  padding: inherit;
  border: none;
  background: inherit;
  font: inherit;
  line-height: normal;
  color: inherit;
  text-align: inherit;
  -webkit-appearance: none;
}

.dd-wrapper .dd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 40px;
  cursor: default;
  cursor: pointer;
  color: #0056b3;
}

.dd-wrapper .dd-header-title {
  margin-right: 5px;
  font-weight: 400;
  font-size: 20px;
}

.dd-wrapper .dd-list {
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 215px;
  border: 1px solid rgb(223,223,223);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
  background-color: white;
  font-weight: 300;
  text-align: left;
}

.dd-list .dd-scroll-list {
  overflow-y: auto;
  max-height: 215px;
  padding: 5px 0;
}

.dd-scroll-list .dd-list-item{
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding: 4px 10px;
  font-size: 18px;
  line-height: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  cursor: pointer;
}

.dd-scroll-list button.dd-list-item:hover {
  background-color: #00539B;
  color: white;
}

a#reports-dropdown {
    
}

.workflow-nav a {
    color: #3C289B;
    font-family: 'AB Gilroy';
    font-size: 16px;
    font-weight: 400;
}

div.active-report {
    border-bottom: 3px solid rgb(0, 0, 0);
    color: #3C289B;
    font-size: 16px;
    font-weight: 400;
    font-family: "AB Gilroy";
    margin-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

a.workflow-nav-item {
    font-family: 'AB Gilroy';
    font-size: 14px;
    margin-top: 9px;
    font-weight: 400;
    color: #3C289B;
}

.workflow-nav {
    padding-left: 15px;
}
